:root {
  --main-color: #0098db;
}


.home {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100vh;
  background: whitesmoke;
  overflow-y: auto;
}

.login {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: whitesmoke;
  overflow-y: auto;
}

.cardHeader {
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerLeft {
  display: flex;
  align-items: center;
}
.headerLeft img {
  margin-right: 10px;
}
.headerLeft h3 {
  font-weight: 400;
}

.cardBody {
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar {
  width: 30px;
  height: 30px;
}

.cardDetails {
  /* padding: 15px; */
  border-bottom: 1px solid #ddd;
}

.lang-selector {
  background: #efefef;
  padding: 0 6px;
  border: 1px solid #ddd !important;
  outline: none !important;
  border-radius: 2px;
}

.floatRight {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 99;
}
.MuiCardContent-root {
  padding: 0 !important;
}

.loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-weight: 300;
  font-size: 18px;
  margin: 0 0 15px 0;
  padding: 0;
}
