body {
  margin: 0;
  font-family: 'Aaux Next', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.column {
  display: flex;
  flex-direction: column;
}
.center {
  justify-content: center;
  align-items: center;
}

.button-menu {
  background: #efefef;
  padding: 0 6px;
  border: 1px solid #ddd !important;
  outline: none !important;
  border-radius: 2px;
}

.header {
  color: #666;
  font-weight: bold;
  font-size: 14px;
  /* text-align: center; */
}

.no-padding {
  padding: 0;
  margin: 0;
}

hr {
  background-color: #ddd;
  border-color: #ddd;
}

.row {
  display: flex;
  align-items: center;
}

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #0098db;
  background-color: #f8f9fa;
  border-radius: 0.25rem;
}

.col-white {
  color: white;
}

.upper {
  text-transform: uppercase;
}

.align-center {
  text-align: center;
}

.small {
  font-size: 12px;
}

.mid {
  font-size: 14px;
}

.space-between {
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.float-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ellipisis:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30%;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

hr {
  height: 1px;
  background-color: #ccc;
  border: none;
  margin-top: 0;
  margin-bottom: 0;
}
