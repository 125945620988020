.form-control {
  outline: none !important;
  box-shadow: none !important;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff !important;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
}
.input-group {
  margin-bottom: 15px !important;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.input-group-addon {
  background: none;
  border: none;
  border-right: 1px solid #ddd;
  position: absolute;
  left: 0;
  z-index: 9999;
  color: #d6d6d6;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border-radius: 0.25rem;
}

.input-group:not(.hide-icon) input {
  border-radius: 4px;
  padding-left: 55px;
  color: #777;
}

.input-group input {
  border: none;
  border-bottom: 1px solid var(--main-color) !important;
  width: 100%;
}

.input-group input:focus + .input-group-addon {
  color: #888 !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0px 9999px white;
}

.login-btn {
  width: 90% !important;
  height: 35px;
  border-radius: 5px !important;
  color: #fff !important;
  cursor: pointer;
  border: none;
}

.primary-color {
  color: var(--main-color) !important;
}

.login-btn:disabled {
  background-color: rgb(216, 216, 216) !important;
  color: #888 !important;
}
